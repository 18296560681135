import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/vant.less'
import './styles/common.less'
import VueAMap from '@vuemap/vue-amap'
import { get, isEmpty } from 'lodash'

Vue.filter('getImg', uuid => {
  if (!uuid) {
    return ''
  }
  if (uuid && uuid.startsWith('http')) {
    return uuid
  }
  return `https://api.saas.famesmart.com/resident-manager/download_self?uuid=${uuid}`
})
Vue.config.productionTip = false
Vue.prototype.$get = get
Vue.prototype.$isEmpty = isEmpty
Vue.prototype.$dateFormat = 'YYYY-MM-DD'
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '3aaff3b0ba41ba7ac1ef4932e16991c4',
  plugin: [
    'AMap.Autocomplete',
    'AMap.Geocoder',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.Marker',
    'AMap.MapType',
    'AMap.AMapManager',
    'AMap.Geolocation'
  ],
  v: '1.4.4'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
