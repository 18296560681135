import Vue from 'vue'
import Vuex from 'vuex'
import { get, compact, find } from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    account: {},
    students: [],
    currentStudent: {},
    currentStudentId: '',
    studentOffAccount: {},
    deptList: [],
    currentUserType: 1
  },
  getters: {
    isAdmin: state => {
      return get(state.account, 'username') === 'admin'
    },
    deptType: state => {
      return get(state.account, 'userType')
    },
    deptName: state => {
      return get(state.account, 'username')
    },
    accountName: state => {
      return get(state.account, 'fullname')
    },
    studentId: state => {
      return get(state.currentStudent, 'id')
    },
    studentName: state => {
      return get(state.currentStudent, 'name')
    },
    rfid: state => {
      return get(state.currentStudent, 'rfid')
    },
    imei: state => {
      return get(state.currentStudent, 'imei')
    }
  },
  mutations: {
    updateUserType(state, num) {
      state.currentUserType = num
    },
    updateDeptList(state, array) {
      state.deptList = array
    },
    updateAccount(state, obj) {
      state.account = obj
    },
    updateImei(state, id) {
      state.currentStudent.imei = id
    },
    updateRfid(state, id) {
      state.currentStudent.rfid = id
    },
    updateCurrentStudentId(state, id) {
      state.currentStudentId = id
      state.currentStudent = find(state.students, item => item.id === id)
    },
    updateStudents(state, array) {
      state.students = compact(array)
      localStorage.setItem('students', JSON.stringify(state.students))
    },
    updateStudentOffAccount(state, obj) {
      localStorage.setItem('studentOffAccount', JSON.stringify(obj))
      state.studentOffAccount = obj
    }
  }
})
