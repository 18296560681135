import axios from 'axios'
import { get } from 'lodash'
import { Toast } from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5000
})

service.interceptors.request.use(
  config => {
    if (localStorage.getItem('token') && !config.headers['token']) {
      config.headers['token'] = localStorage.getItem('token')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 20000 && res.result !== 0 && res.result !== 1) {
      /* Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      }) */
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        /* MessageBox.confirm(
          '你已被登出，可以取消继续留在该页面，或者重新登录',
          '确定登出',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          // UserModule.ResetToken()
          location.reload()
        }) */
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response.data
    }
  },
  error => {
    if (get(error, 'response.status') === 403) {
      localStorage.removeItem('token')
    }
    Toast(`${error}, 请刷新后重试`)
    return Promise.reject(error)
  }
)

export default service
