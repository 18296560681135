<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { fetchDepList } from '@/api/user'

export default {
  created() {
    let url = window.location.pathname
    if (url.includes('attendance-statistics')) {
      return
    }

    if (!localStorage.getItem('token') || !localStorage.getItem('account')) {
      this.goLogin()
      return
    }
    try {
      this.$store.commit(
        'updateAccount',
        JSON.parse(localStorage.getItem('account'))
      )
    } catch (e) {
      this.goLogin()
    }
  },
  methods: {
    async getDepartList() {
      const { data: res } = await fetchDepList()
      this.$store.commit('updateDeptList', res || [])
    },
    goLogin() {
      localStorage.removeItem('token')
      localStorage.removeItem('account')
      this.$router.push({ name: 'LoginPage' })
    }
  }
}
</script>

<style lang="less">
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  max-width: 480px;
  margin: auto;
  position: relative;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
