import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  ...[
    {
      path: '/',
      name: 'LoginPage',
      component: () => import('@/views/Login/LoginPage')
    },
    {
      path: '/home',
      name: 'HomePage',
      meta: { title: '首页' },
      component: () => import('@/views/HomePage.vue')
    },
    {
      path: '/replay',
      name: 'ReplayView',
      meta: { title: '轨迹回放' },
      component: () => import('@/views/Replay/ReplayView')
    },
    {
      path: '/live',
      name: 'LiveView',
      meta: { title: '实时监控' },
      component: () => import('@/views/Live/LiveView')
    },
    {
      path: '/attendance',
      name: 'AttendanceView',
      meta: { title: '考勤统计' },
      component: () => import('@/views/Attendance/AttendanceView')
    },
    {
      path: '/attendance-statistics',
      name: 'AttendanceStatistics',
      meta: { title: '考勤统计' },
      component: () => import('@/views/Attendance/AttendanceStatistics')
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/views/ErrorPage.vue')
    }
  ]
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(to => {
  document.title = to.meta.title || '电子工作证'
})

export default router
