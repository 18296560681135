import request from '@/api/request'

/**
 * @param {string} username username
 * @param {string} password password
 */
export const login = ({ username, password }) => {
  return request({
    url: '/login',
    method: 'POST',
    data: { username, password }
  })
}

/*
 * get account basic information
 */
export const fetchUserInfo = () => {
  return request({
    url: '/users/me',
    method: 'GET'
  })
}

/**
 * get department list
 * @param {string} deep 0:一级部门, 1:二级部门
 * @param {string} parentCode 1000, 2000, 3000
 **/
export const fetchDepList = (deep = 0, parentCode) => {
  return request({
    url: '/dept/deptList',
    method: 'GET',
    params: { deep, parentCode }
  })
}

/**
 * get area droplist
 * @param {number} imei identity
 */
export const fetchAreaDrop = () => {
  return request({
    url: '/area/areas',
    method: 'GET'
  })
}

/**
 * get school droplist
 * @param {number} areaId
 */
export const fetchSchoolDrop = areaId => {
  return request({
    url: '/school/schools',
    method: 'GET',
    params: { areaId }
  })
}

/**
 * get grades droplist
 * @param {number} commCode
 */
export const fetchGradesDrop = commCode => {
  return request({
    url: '/grade/grades',
    method: 'GET',
    params: { commCode }
  })
}

/**
 * get classes droplist
 * @param {number} gradeId
 */
export const fetchClassesDrop = gradeId => {
  return request({
    url: '/classes/classes',
    method: 'GET',
    params: { gradeId }
  })
}

/**
 * get students droplist
 * @param {number} classId
 */
export const fetchStudentsDrop = classId => {
  return request({
    url: '/student/students',
    method: 'GET',
    params: { classId }
  })
}
